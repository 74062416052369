import React from 'react';

import './App.css';

import ResponsiveLayout from './ResponsiveLayout';

function App() {
  return (
    <div>
      <ResponsiveLayout />
    </div>
  );
}

export default App;
